@font-face {
    font-family: 'Inter';
    src: local('Inter'), url(./Inter-VariableFont.ttf) format('truetype');
    font-weight: 100 900;
  }

@font-face {
    font-family: 'Permanent Marker';
    src: local('Permanent Marker'), url(./PermanentMarker-Regular.ttf) format('truetype');
    font-weight: 400;
  }

@font-face {
    font-family: 'Quicksand';
    src: local('Quicksand'), url(./Quicksand-VariableFont_wght.ttf) format('truetype');
    font-weight: 300 400 500 600 700;
  }

@font-face {
    font-family: 'Open Sauce';
    src: local('Open-Sauce-Regular'), url(./OpenSauceOne-Regular.ttf) format('truetype');
    font-weight: 400;
    font-style: normal;
  }
@font-face {
    font-family: 'Open Sauce';
    src: local('Open-Sauce-Regular'), url(./OpenSauceOne-Italic.ttf) format('truetype');
    font-weight: 400;
    font-style: italic;
  }
  @font-face {
      font-family: 'Open Sauce';
      src: local('Open-Sauce-Medium'), url(./OpenSauceOne-Medium.ttf) format('truetype');
      font-weight: 500;
      font-style: normal;
    }
  @font-face {
      font-family: 'Open Sauce';
      src: local('Open-Sauce-MediumItalic'), url(./OpenSauceOne-MediumItalic.ttf) format('truetype');
      font-weight: 500;
      font-style: italic;
    }
@font-face {
    font-family: 'Open Sauce';
    src: local('Open-Sauce-Bold'), url(./OpenSauceOne-Bold.ttf) format('truetype');
    font-weight: 700;
    font-style: normal;
  }
@font-face {
    font-family: 'Open Sauce';
    src: local('Open-Sauce-BoldItalic'), url(./OpenSauceOne-BoldItalic.ttf) format('truetype');
    font-weight: 700;
    font-style: italic, oblique;
  }
@font-face {
    font-family: 'Open Sauce';
    src: local('Open-Sauce-SemiBold'), url(./OpenSauceOne-SemiBold.ttf) format('truetype');
    font-weight: 600;
    font-style: normal;
  }
@font-face {
    font-family: 'Open Sauce';
    src: local('Open-Sauce-SemiBoldItalic'), url(./OpenSauceOne-SemiBold.ttf) format('truetype');
    font-weight: 600;
    font-style: italic, oblique;
  }